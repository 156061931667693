import { useEffect, useState } from 'react';
import './App.css';
import { getFailedCalls } from './components/FailedCalls';
import { arrayElementsToLines } from './components/DebugLogLines'
function App() {
    const [failedCalls, setFailedCalls] = useState([]);
    useEffect(() => {
        getFailedCalls()
            .then(json => {
                setFailedCalls(json);
                console.log(json);
            })
    }, []);

    return (
        <div className="">
                <h1>Failed Calls</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Call startDateTime</th>
                            <th>Call Disposition</th>
                            <th>Logs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {failedCalls.map((call:any, index) => (
                            <tr key={index}>
                                <td>{call.startDateTime}</td>
                                <td>{call.disposition}</td>
                                <td>
                                    <pre>
                                        {arrayElementsToLines(call.debugLogLines)}
                                    </pre>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    );
}

export default App;
